<template>
  <!-- BEGIN: Content-->
  <div class="body-login">
    <div class="content-overlay" />
    <div class="content-wrapper">
      <div class="content-header row" />
      <div class="content-body">
        <div class="auth-wrapper auth-cover">
          <div class="auth-inner row m-0">
            <!-- Brand logo-->
            <a
              class="brand-logo"
              href="https://www.icrea.cat"
            />

            <!-- /Brand logo-->
            <!-- Left Text-->
            <div
              class="d-none d-lg-flex col-lg-8 align-items-center p-5"
              :style="{'background': 'url(' + require('@/assets/images/illustration/bg-login.svg') + ') center center no-repeat', 'background-size': 'cover'}"
            >
              <div class="w-100 d-lg-flex align-items-center justify-content-center flex-column px-5">
                <div class="mb-4">
                  <img
                    class="img-fluid"
                    :src="require('@/assets/images/logo/logo-icrea-white-login.svg')"
                    alt="ICREA"
                  >
                </div>
                <!-- <div class="quote">
                  <h1><em>"You never fail until you stop trying"</em></h1>
                  <hr>
                  <p>Albert Einstein</p>
                </div> -->
              </div>
            </div>
            <!-- /Left Text-->
            <!-- Login-->
            <div class="d-flex col-lg-4 align-items-center auth-bg px-2 p-lg-5">
              <div class="col-12 col-sm-8 col-md-6 col-lg-12 px-xl-2 mx-auto">
                <h2 class="card-title fw-bold mb-1">
                  Generate a new password
                </h2>
                <p class="card-text mb-2">
                  Enter the email with which you registered and you will receive a new password.
                </p>
                <form
                  class="auth-login-form mt-2"
                  action="index.html"
                  method="POST"
                >
                  <div class="mb-1">
                    <label
                      class="form-label"
                      for="login-email"
                    >Email</label>
                    <input
                      id="login-email"
                      v-model="email"
                      class="form-control"
                      type="email"
                      name="login-email"
                      placeholder="john@example.com"
                      aria-describedby="login-email"
                      autofocus=""
                      tabindex="1"
                    >
                  </div>

                  <!-- <button class="btn btn-primary w-100" tabindex="4">Generate and send new password</button> -->
                  <a
                    v-if="!loading"
                    href="#"
                    class="btn btn-primary w-100"
                    tabindex="4"
                    data-bs-toggle="modal"
                    data-bs-target="#modalAcceptPrivacy"
                    @click="sendEmail"
                  >Generate and send new password</a>
                  <b-button
                    v-else
                    type="submit"
                    disabled
                  >
                    <div class="loading">
                      <div class="effect-1 effects" />
                      <div class="effect-2 effects" />
                      <div class="effect-3 effects" />
                    </div>
                  </b-button>
                </form>
                <p class="text-center mt-2">
                  <a
                    @click="$route.name == 'reset-password-intranet' ? $router.push({ name: 'admin.login' }) : $router.push({ name: 'front.login' })"
                  ><span>Back to login</span></a>
                </p>
              </div>
            </div>
            <!-- /Login-->
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- END: Content-->
</template>

<script>
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

require('@core/scss/core.scss')
require('@/assets/scss/style.scss')

export default {
  data() {
    return {
      email: '',
      loading: false,
    }
  },
  methods: {
    sendEmail() {
      this.loading = true
      if (this.email.length > 0) {
        this.$store.dispatch('auth/forgotPassword', this.email).then(() => {
          if (this.$route.name == 'reset-password-intranet') {
            this.$router.push({ name: 'reset-password-sended-intranet' })
          } else {
            this.$router.push({ name: 'reset-password-sended' })
          }
        }).catch(exception => {
          this.loading = false
          this.$toast({
            component: ToastificationContent,
            props: {
              title: exception,
              icon: 'ErrorIcon',
              variant: 'error',
            },
          })
        })
      } else {
        this.loading = false
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Please, fill up an email',
            icon: 'ErrorIcon',
            variant: 'error',
          },
        })
      }
    },
  },
}
</script>

<style>

</style>
